// packages
import { z } from "zod";
import { useFieldArray, useFormContext } from "react-hook-form";

// components
import { PetInfoEditor } from "@/shared/components/PetInfoEditor";
import { Badge, Button } from "@/shared/components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

// hooks
import { useModal } from "@/shared/hooks/useModal";
import { useBreeds } from "@/shared/hooks/useBreeds";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// utils
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { TOP_BREEDS } from "@/shared/utils/constants";
import Strings from "@/shared/utils/Strings.constants";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

// types
import { FormStepProps, OtherProps } from "@/shared/types/Form";
import { PetSchema, Quote } from "@/shared/types/Quote.interface";
import { UsPolicyStepSchema } from "@/quote-ptz-us/src/schema/PtzUsQuote";

// config
import { PtzUsDataUtils } from "../utils/PtzUsDataUtils";

type AllPolicyStepProps = z.infer<typeof UsPolicyStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllPolicyStepProps>;

export function PolicyEditor(props: FormStepProps<Quote, StepKeys, OtherProps | undefined> & { isNoStepVariant?: boolean }) {
    const { isNoStepVariant } = props;
    const queryParams = AnalyticsUtils.getQueryParams(props.value?.extra?.queryParams);
    const modal = useModal();

    const { appState } = useAppLayerContext();
    const { underwriter, updateQuote, isQuoteUpdating, isMultiPetLinked, labelVariant } = appState;
    const { breeds } = useBreeds(underwriter);
    const { control, watch } = useFormContext<AllPolicyStepProps>();
    const { watch: watchParent, getValues: getParentValues } = useFormParentContext<Quote>();
    const quoteId = watchParent(`id`);

    const { append, remove } = useFieldArray({
        control,
        name: "policies"
    });

    const policies = watch("policies");

    const handleAddPet = () => {
        const firstPetCoverageSettings = policies[0]?.coverageSettings;
        const newPolicy = QuoteDataUtils.createNewPolicyUS(isMultiPetLinked ? firstPetCoverageSettings : undefined);
        append(newPolicy as z.infer<typeof PetSchema>);
    };

    const handleDeletePet = async (index: number) => {
        if (!(await modal.confirm())) {
            return;
        }

        const deletedPolicyId = policies[index]?.id;
        remove(index);

        if (!!quoteId) {
            const parentValues = getParentValues();
            const updatedParentPolicies = parentValues.policies?.filter(p => p.id !== deletedPolicyId) ?? parentValues.policies;
            const updatedParentValues = { ...parentValues, policies: updatedParentPolicies };
            updateQuote?.mutate(updatedParentValues);
        }
    };
    const fieldLabels = PtzUsDataUtils.getPetStepFieldLabels(labelVariant);

    return (
        <div className="flex flex-1 flex-col gap-6">
            {policies.map((field, index) => {
                return (
                    <div key={field?.id ?? `field-${index}`} data-testid="pet-row" className={labelVariant === "default" && index > 0 ? "mt-2" : ""}>
                        <PetInfoEditor
                            petBreeds={breeds}
                            topBreeds={TOP_BREEDS}
                            policyIndex={index}
                            showAge={true}
                            allowDelete={policies?.length > 1}
                            onDelete={async () => await handleDeletePet(index)}
                            styles={{
                                wrapper: "grid grid-cols-2 gap-6 sm:grid-cols-11 lg:grid-cols-12",
                                fields: {
                                    name: "order-1 col-span-2 sm:col-span-7 lg:col-span-3",
                                    age: "order-2 col-span-2 sm:col-span-4 lg:col-span-2",
                                    species: "order-3 col-span-1 sm:col-span-3 lg:col-span-2",
                                    gender: "order-4 col-span-1 sm:col-span-3 lg:col-span-2",
                                    breed: "order-5 col-span-2 sm:col-span-5 lg:col-span-3"
                                }
                            }}
                            containBreedDropdown
                            ageField={{ constrainSelectWidthToTrigger: true, label: fieldLabels.age.label, placeholder: fieldLabels.age.placeholder }}
                            nameField={{ label: fieldLabels.name.label }}
                            breedField={{ label: fieldLabels.breed.label, placeholder: fieldLabels.breed.placeholder }}
                        />
                    </div>
                );
            })}

            {!isNoStepVariant && (
                <div className="flex flex-col items-center justify-center gap-2 sm:flex-row sm:justify-start">
                    <Button variant="ghost" startDecorator={<FontAwesomeIcon icon={faPlus} />} className="text-nowrap" onClick={handleAddPet} disabled={isQuoteUpdating}>
                        {Strings.ADD_ADDITIONAL_PET}
                    </Button>
                    {/* todo: should we make this rounded breakpoint change universal for Badge component */}
                    <Badge variant="secondary" className="rounded-md sm:rounded-full">
                        {Strings.MULTIPET_DISCOUNT_LABEL}
                    </Badge>
                </div>
            )}
            <hr className="mb-7 border-stroke-primary" />
            {modal.render}
        </div>
    );
}
